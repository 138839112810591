import { useWeb3Modal } from "@web3modal/wagmi/react";
import React, { useEffect } from "react";
import { useAccount } from "wagmi";
import logo from "../../Image/logo.png";
import casino from "../../Image/casino.png";
import SearchIcon from "@mui/icons-material/Search";

export default function Header() {
  const { open } = useWeb3Modal();
  const { address, isConnected, isDisconnected } = useAccount();

  // const SignIn = () => {
  //   axiosInstance
  //     .post(API_URLS.SignUp, { Bit_Account_Address: address })
  //     .then((res) => {
  //       // toast.success("Unstaking Successful");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center bg-[#940606] px-32 py-4">
        <div className="flex justify-center items-center">
          <img src={logo} alt="" />
        </div>
        <div className="flex justify-center items-center gap-8">
          <span className="flex gap-3 justify-center items-center">
            <img src={casino} alt="" className="h-5 w-5" />
            <p className="text-white font-bold">Casino</p>
          </span>
          <span className="flex gap-3 justify-center items-center">
            <SearchIcon className="text-white !text-3xl" />
          </span>
          <span className="flex gap-3 justify-center items-center cursor-pointer">
            <h3 className="px-5 py-2 bg-black bg-opacity-30 text-white">
              Sing In
            </h3>
          </span>
        </div>
      </div>
    </div>
  );
}
