import React from "react";
import Home from "./Component/Home";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { bsc, bscTestnet, mainnet } from "viem/chains";
import { Staking } from "./Component/Context/StakingApp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Roulete from "./Games/Roulete";
import Play from "./Games/Play";
import Deposit from "./Games/Deposit";
import RouletteSpin from "./Games/RouletteSpin";
import Header from "./Component/Header";

function App() {
  const projectId = "0345c7230f52356034d20c7c5fbf2519";
  // 2. Create wagmiConfig
  const metadata = {
    name: "puremint",
    description: "Welcome to PureMint, It is future of innovation and finance",
    url: "https://puremint.io/",
    icons: ["https://puremint.io/logo/Puremint-logo.png"],
  };

  const chains = [bsc, bscTestnet, mainnet];
  const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

  // 3. Create modal
  createWeb3Modal({ wagmiConfig, projectId, chains });
  return (
    <div className="bg-black ">
      <WagmiConfig config={wagmiConfig}>
        <BrowserRouter>
          <Header />

          <Staking>
            <Routes>
              <Route path="/" element={<RouletteSpin />}></Route>
              {/* <Route path="/" element={<Home />}></Route>
              <Route path="/play" element={<Play />}></Route>
              <Route path="/deposit" element={<Deposit />}></Route>
              <Route path="/roulete" element={<Roulete />}></Route>
              <Route path="/roulette-spin" element={<RouletteSpin />}></Route> */}
            </Routes>
          </Staking>

          <ToastContainer />
        </BrowserRouter>
      </WagmiConfig>
    </div>
  );
}

export default App;
