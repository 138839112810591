import React, { useEffect, useRef, useState } from "react";
import { RouletteTable, RouletteWheel } from "react-casino-roulette";
import "react-casino-roulette/dist/index.css";
import video from "../../Video/rolet.mp4";
import UndoIcon from "@mui/icons-material/Undo";
import RefreshIcon from "@mui/icons-material/Refresh";
import SyncIcon from "@mui/icons-material/Sync";
import CloseIcon from "@mui/icons-material/Close";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/apiUrls";
import { useAccount } from "wagmi";
import { Button, Dialog } from "@mui/material";
import bikeWheelSound from "../../Video/mixkit-bike-wheel-spinning-1613.wav";

export default function RouletteSpin() {
  const [start, setStart] = useState(false);
  const [winningBet, setWinningBet] = useState("-1");
  const [bets, setBets] = useState({});
  const [selectedCoin, setSelectedCoin] = useState(5);
  const [value, setValue] = useState(selectedCoin);
  const [coin, setCoin] = useState("https://i.ibb.co/qFs5k4b/coin1.png");
  const { address } = useAccount();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [subId, setSubId] = useState("");
  const audioRef = useRef(new Audio(bikeWheelSound));
  const [number, setNumber] = useState("");

  // State to track if the audio is playing

  const handleClose = () => {
    setOpen(false);
    setValue(selectedCoin);
  };
  const handelopen = () => {
    setOpen(true);
  };

  const handleAudio = (action) => {
    const audio = audioRef.current;

    switch (action) {
      case "play":
        audio.play();

        break;
      case "pause":
        audio.pause();

        break;
      case "stop":
        audio.pause();
        audio.currentTime = 0; // Reset to the beginning

        break;
      default:
        break;
    }
  };

  const GamePlay = () => {
    axiosInstance
      .post(API_URLS.GamePlayAPI, {})
      .then((res) => {
        // toast.success("Unstaking Successful");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GamePlay();
  }, []);

  const Bet = () => {
    axiosInstance
      .post(API_URLS.BidAPI, {
        Game_Id: 1,
        Bidresult_Id: id,
        playId: subId,
        UserId: address,
        Amount: value,
      })
      .then((res) => {
        setId("");
        setSubId("");
        handleClose();
        // toast.success("Unstaking Successful");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleChange(num) {
    setValue(() => {
      if (value === 5) {
        return num;
      } else {
        return Number(value + num);
      }
    });
  }

  const handleBet = (betData) => {
    const { id } = betData;

    console.log(id, "shivam");

    handleAudio("stop");

    if (id === "0") {
      setId(1);
      setSubId(id);
    } else if (id === "00") {
      setId(1);
      setSubId(id);
    } else if (id <= Number("36")) {
      setId(6);
      setSubId(id);
    } else if (id === "3RD_COLUMN") {
      setId(2);
      setSubId(3);
    } else if (id === "2ND_COLUMN") {
      setId(2);
      setSubId(2);
    } else if (id === "1ST_COLUMN") {
      setId(2);
      setSubId(1);
    } else if (id === "3RD_DOZEN") {
      setId(4);
      setSubId(3);
    } else if (id === "2ND_DOZEN") {
      setId(4);
      setSubId(2);
    } else if (id === "1ST_DOZEN") {
      setId(4);
      setSubId(1);
    } else if (id === "1_TO_18") {
      setId(5);
      setSubId(1);
    } else if (id === "EVEN") {
      setId(5);
      setSubId(2);
    } else if (id === "RED") {
      setId(5);
      setSubId(3);
    } else if (id === "BLACK") {
      setId(5);
      setSubId(4);
    } else if (id === "ODD") {
      setId(5);
      setSubId(5);
    } else if (id === "19_TO_36") {
      setId(5);
      setSubId(6);
    } else {
    }

    handelopen();

    setBets((prevState) => ({
      ...prevState,
      [id]: { id: id, icon: `${coin}` },
    }));
  };
  function getRandomNumber() {
    setNumber(Math.floor(Math.random() * 37)); // 37 because it generates numbers between 0 and 36
  }

  // const doSpin = () => {
  //   setWinningBet("25");
  //   setStart(true);
  // };

  useEffect(() => {
    if (number) {
      setWinningBet(number.toString());
      setStart(true);
      AidoStopFunction();
    }
  }, [number]);
  const AidoStopFunction = () => {
    setTimeout(() => {
      handleAudio("stop"); // Call stop function to clear interval
    }, 9000);
  };
  return (
    <div className="video-background">
      <video
        className="h-full w-full"
        src={video}
        autoPlay
        loop
        preload="auto"
        playsInline
        muted
      ></video>
      <div className="content">
        <div className="  lg:h-screen p-5 bg-black bg-opacity-50    ">
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <RouletteWheel start={start} winningBet={winningBet} />
            </div>
          </div>
          <div style={{ maxWidth: 1200, margin: "auto" }}>
            <RouletteTable bets={bets} onBet={handleBet} />
          </div>
          <div className="flex justify-between items-center w-full  px-[11.5%] mt-8 ">
            <div className="flex w-[50%] justify-between items-center">
              <span
                onClick={() => {
                  handleChange(5);
                  setSelectedCoin(5);
                  setCoin("https://i.ibb.co/qFs5k4b/coin1.png");
                }}
                className=" bg-white rounded-full h-20 w-20 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#d472d4] via-[#a024ad]  to-[#880a6d]"
              >
                {selectedCoin === 5 ? (
                  <img src="https://i.ibb.co/qFs5k4b/coin1.png" alt="" />
                ) : (
                  <img
                    style={{ filter: "blur(2px)" }}
                    src="https://i.ibb.co/qFs5k4b/coin1.png"
                    alt=""
                  />
                )}
              </span>

              <span
                onClick={() => {
                  handleChange(10);
                  setSelectedCoin(10);
                  setCoin("https://i.ibb.co/W3Pbvzj/coin2.png");
                }}
                className=" bg-white rounded-full  h-20 w-20 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#70d6c5] via-[#c1d9ec]  to-[#6929bd]"
              >
                {selectedCoin === 10 ? (
                  <img src="https://i.ibb.co/W3Pbvzj/coin2.png" alt="" />
                ) : (
                  <img
                    style={{ filter: "blur(2px)" }}
                    src="https://i.ibb.co/W3Pbvzj/coin2.png"
                    alt=""
                  />
                )}
              </span>
              <span
                onClick={() => {
                  handleChange(50);
                  setSelectedCoin(50);
                  setCoin("https://i.ibb.co/RTqzngv/coin3.png");
                }}
                className=" bg-white rounded-full  h-20 w-20 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#c56ea1] via-[#eea3c9]  to-[#d664c3]"
              >
                {selectedCoin === 50 ? (
                  <img src="https://i.ibb.co/RTqzngv/coin3.png" alt="" />
                ) : (
                  <img
                    style={{ filter: "blur(2px)" }}
                    src="https://i.ibb.co/RTqzngv/coin3.png"
                    alt=""
                  />
                )}
              </span>
              <span
                onClick={() => {
                  handleChange(100);
                  setSelectedCoin(100);
                  setCoin("https://i.ibb.co/gmZbQzJ/coin4.png");
                }}
                className=" bg-white rounded-full  h-20 w-20 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#7f87f0] via-[#c1bdf1]  to-[#773fc0]"
              >
                {selectedCoin === 100 ? (
                  <img src="https://i.ibb.co/gmZbQzJ/coin4.png" alt="" />
                ) : (
                  <img
                    style={{ filter: "blur(2px)" }}
                    src="https://i.ibb.co/gmZbQzJ/coin4.png"
                    alt=""
                  />
                )}
              </span>
              <span
                onClick={() => {
                  handleChange(500);
                  setSelectedCoin(500);
                  setCoin("https://i.ibb.co/1fyPGG4/coin5.png");
                }}
                className=" bg-white rounded-full  h-20 w-20 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#7aecc6] via-[#6bd47d]  to-[#b2d356]"
              >
                {selectedCoin === 500 ? (
                  <img src="https://i.ibb.co/1fyPGG4/coin5.png" alt="" />
                ) : (
                  <img
                    style={{ filter: "blur(2px)" }}
                    src="https://i.ibb.co/1fyPGG4/coin5.png"
                    alt=""
                  />
                )}
              </span>
            </div>
            <div className="w-[10%] items-center justify-center"></div>
            <div className="flex w-[40%] justify-between items-center">
              <span className=" bg-white rounded-full  h-16 w-16 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#daeaff] via-[#4ba2e5]  to-[#00bbff]">
                <UndoIcon className="!text-5xl !font-bold" />
              </span>
              <span className=" bg-white rounded-full  h-16 w-16 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#daeaff] via-[#4ba2e5]  to-[#00bbff]">
                <RefreshIcon className="!text-5xl !font-bold" />
              </span>
              <span
                onClick={() => setStart(false)}
                className=" bg-white rounded-full  h-16 w-16 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#daeaff] via-[#4ba2e5]  to-[#00bbff]"
              >
                <CloseIcon className="!text-5xl !font-bold" />
              </span>
              <span
                onClick={() => {
                  getRandomNumber();
                  handleAudio("play");
                }}
                className=" bg-white rounded-full  h-24 w-24 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#daeaff] via-[#4ba2e5]  to-[#00bbff]"
              >
                <SyncIcon className="!text-5xl !font-bold" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          className:
            "!min-w-[20%] !min-h-[25%] p-5 flex gap-3 flex-col !rounded-2xl !text-shadow-none  !bg-black !bg-opacity-75",
        }}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="flex justify-center items-center my-5 bg-green-500 p-5  rounded-full">
            <p className="text-black font-bold text-5xl">{value}</p>
          </div>
          <div className="flex  justify-between items-center gap-3">
            <span
              onClick={() => {
                handleChange(5);
                // setCoin("https://i.ibb.co/qFs5k4b/coin1.png");
              }}
              className=" bg-white  rounded-full h-14 w-14 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#d472d4] via-[#a024ad]  to-[#880a6d]"
            >
              <img src="https://i.ibb.co/qFs5k4b/coin1.png" alt="" />
            </span>

            <span
              onClick={() => {
                handleChange(10);
                // setCoin("https://i.ibb.co/W3Pbvzj/coin2.png");
              }}
              className=" bg-white rounded-full  h-14 w-14 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#70d6c5] via-[#c1d9ec]  to-[#6929bd]"
            >
              <img src="https://i.ibb.co/W3Pbvzj/coin2.png" alt="" />
              {/* <p className="text-center font-bold">10</p> */}
            </span>
            <span
              onClick={() => {
                handleChange(50);
                // setCoin("https://i.ibb.co/RTqzngv/coin3.png");
              }}
              className=" bg-white rounded-full  h-14 w-14 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#c56ea1] via-[#eea3c9]  to-[#d664c3]"
            >
              <img src="https://i.ibb.co/RTqzngv/coin3.png" alt="" />
            </span>
            <span
              onClick={() => {
                handleChange(100);
                // setCoin("https://i.ibb.co/gmZbQzJ/coin4.png");
              }}
              className=" bg-white rounded-full  h-14 w-14 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#7f87f0] via-[#c1bdf1]  to-[#773fc0]"
            >
              <img src="https://i.ibb.co/gmZbQzJ/coin4.png" alt="" />
            </span>
            <span
              onClick={() => {
                handleChange(500);
                // setCoin("https://i.ibb.co/1fyPGG4/coin5.png");
              }}
              className=" bg-white rounded-full  h-14 w-14 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#7aecc6] via-[#6bd47d]  to-[#b2d356]"
            >
              <img src="https://i.ibb.co/1fyPGG4/coin5.png" alt="" />
            </span>
            {/* <span className=" bg-white rounded-full  h-14 w-14 text-xs shadow-md flex justify-center shadow-black text-center items-center  cursor-pointer bg-gradient-to-r  from-[#f87fde] via-[#d387d3]  to-[#920774]">
                <p className="text-center font-bold">2000</p>
              </span> */}
          </div>
          <button
            type="submit"
            className="bg-green-500 text-2xl font-bold my-3 px-10 py-2 rounded-2xl"
            onClick={Bet}
          >
            Ok
          </button>
        </div>
      </Dialog>
    </div>
  );
}
