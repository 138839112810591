import axios from "axios";

// export const baseURL1 = "http://192.168.29.84:4000/";
export const baseURL1 = "https://game.igtglobal.io/";

const axiosInstance = axios.create({
  baseURL: baseURL1,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/x-www-form-urlencoded",
      
    };
    return config;
  },
  (err) => Promise.reject(err)
);

export default axiosInstance;
