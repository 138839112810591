export const ACTION_TYPES = {
  0: '0',
  '00': '00',
  STREET: 'STREET',
  ROW: 'ROW',
  BASKET_US: 'BASKET_US',
  SPLIT: 'SPLIT',
  CORNER: 'CORNER',
  DOUBLE_STREET: 'DOUBLE_STREET',
  STRAIGHT_UP: 'STRAIGHT_UP',
  '1ST_COLUMN': '1ST_COLUMN',
  '2ND_COLUMN': '2ND_COLUMN',
  '3RD_COLUMN': '3RD_COLUMN',
  '1ST_DOZEN': '1ST_DOZEN',
  '2ND_DOZEN': '2ND_DOZEN',
  '3RD_DOZEN': '3RD_DOZEN',
  '1_TO_18': '1_TO_18',
  '19_TO_36': '19_TO_36',
  EVEN: 'EVEN',
  ODD: 'ODD',
  RED: 'RED',
  BLACK: 'BLACK',
};
