"use Server";
import React, { useState, createContext } from "react";
import { useContractRead } from "wagmi";
import { useAccount } from "wagmi";
import { useContractWrite } from "wagmi";
import { toast } from "react-toastify";
import {
  StakingAbi1,
  stakingAddress1,
  StakingAbi2,
  stakingAddress2,
  approveAbi,
  approvetoken,
} from "./constant";

export const StakingApp = createContext();

export const Staking = ({ children }) => {
  const { address } = useAccount();
  const [amount1, setAmount1] = useState();
  const [lockTime, setLockTime] = useState();
  const [lockingTime, setLockingTime] = useState();
  const [lockdays, setLockDays] = useState();
  const [entarlock, setEntarlock] = useState("");
  const [AllowanceAmunt1, setAllowanceAmunt1] = useState();

  const [amount2, setAmount2] = useState();
  const [lockTime2, setLockTime2] = useState();
  const [lockingTime2, setLockingTime2] = useState();
  const [lockdays2, setLockDays2] = useState();
  const [entarlock2, setEntarlock2] = useState("");
  const [AllowanceAmunt2, setAllowanceAmunt2] = useState();

  //staking 1 start

  useContractRead({
    address: stakingAddress1,
    abi: StakingAbi1,
    functionName: "check",
    args: [address],
    onSuccess(data) {
      setAmount1(data[0].toString());
      setLockTime(Number(data[1]) * 1000);
    },

    watch: true,
  });
  useContractRead({
    address: stakingAddress1,
    abi: StakingAbi1,
    functionName: "getLokingDays",
    // args: [address],
    onSuccess(data) {
      setLockDays(data.toString());
    },

    watch: true,
  });
  useContractRead({
    address: stakingAddress1,
    abi: StakingAbi1,
    functionName: "LockingTime",
    // args: [address],
    onSuccess(data) {
      setLockingTime(Number(data) * 1000);
    },

    watch: true,
  });
  useContractRead({
    address: approvetoken,
    abi: approveAbi,
    functionName: "allowance",
    args: [address, stakingAddress1],
    onSuccess(data) {
      setAllowanceAmunt1(Number(data) * 1000);
    },

    watch: true,
  });

  const { write: approveusd, isLoading: val3 } = useContractWrite({
    address: approvetoken,
    abi: approveAbi,
    functionName: "approve",
    args: [
      stakingAddress1,
      "1157920892373161954235709850086879078532699846656405640394575840079131296399",
    ],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Approve Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Approveusd is Successful ${data.hash.toString()}`);
    },
  });
  const { write: lock, isLoading: val4 } = useContractWrite({
    address: stakingAddress1,
    abi: StakingAbi1,
    functionName: "lock",
    args: [entarlock],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Lock Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Louck is Successful ${data.hash.toString()}`);
    },
  });
  const { write: Unlock, isLoading: val5 } = useContractWrite({
    address: stakingAddress1,
    abi: StakingAbi1,
    functionName: "Unlock",
    //  args: [entarlock],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Unlock Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Unlock is Successful ${data.hash.toString()}`);
    },
  });

  //stakin 1 end

  //staking 2 start

  useContractRead({
    address: stakingAddress2,
    abi: StakingAbi2,
    functionName: "check",
    args: [address],
    onSuccess(data) {
      setAmount2(data[0].toString());
      setLockTime2(Number(data[1]) * 1000);
    },

    watch: true,
  });
  useContractRead({
    address: stakingAddress2,
    abi: StakingAbi2,
    functionName: "getLokingDays",
    // args: [address],
    onSuccess(data) {
      setLockDays2(data.toString());
    },

    watch: true,
  });
  useContractRead({
    address: stakingAddress2,
    abi: StakingAbi2,
    functionName: "LockingTime",
    // args: [address],
    onSuccess(data) {
      setLockingTime2(Number(data) * 1000);
    },

    watch: true,
  });
  useContractRead({
    address: approvetoken,
    abi: approveAbi,
    functionName: "allowance",
    args: [address, stakingAddress2],
    onSuccess(data) {
      setAllowanceAmunt2(Number(data) * 1000);
    },

    watch: true,
  });

  const { write: approveusd2, isLoading: val2 } = useContractWrite({
    address: approvetoken,
    abi: approveAbi,
    functionName: "approve",
    args: [
      stakingAddress2,
      "1157920892373161954235709850086879078532699846656405640394575840079131296399",
    ],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Approve Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Approveusd is Successful ${data.hash.toString()}`);
    },
  });
  const { write: lock2, isLoading: val6 } = useContractWrite({
    address: stakingAddress2,
    abi: StakingAbi2,
    functionName: "lock",
    args: [entarlock],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Lock Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Louck is Successful ${data.hash.toString()}`);
    },
  });
  const { write: Unlock2, isLoading: val7 } = useContractWrite({
    address: stakingAddress2,
    abi: StakingAbi2,
    functionName: "Unlock",
    //  args: [entarlock],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Unlock Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Unlock is Successful ${data.hash.toString()}`);
    },
  });

  //stakin 1 end
  return (
    <StakingApp.Provider
      value={{
        amount1,
        lockTime,
        lockdays,
        lockingTime,
        entarlock,
        AllowanceAmunt1,
        setEntarlock,
        lock,
        Unlock,
        amount2,
        lockTime2,
        lockdays2,
        lockingTime2,
        entarlock2,
        AllowanceAmunt2,
        setEntarlock2,
        lock2,
        Unlock2,
        approveusd,
        approveusd2,
      }}
    >
      {children}
    </StakingApp.Provider>
  );
};
